.app {
  text-align: center;
}

.app-header {
  background-color: #f7f8f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  position:absolute;
  top: 20px;
  left: 20px;
}

.lavon {
  height: 90vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

